import { switchMap } from 'rxjs/operators';
import { AnalyticsService } from '../../../services/analytics.service';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AnalyticsRequestBody } from 'app/models/analyticsReqBody.interface';
import { Subject } from 'rxjs';
import { AnalyticsDataObj } from 'app/models/analyticsDataObj.interface';
// import { AnalyticsDataObj } from 'app/models/analyticsDataObj.interface';
// import { dummyData, dummyDataDay, dummyDataMonth, dummyDataNone } from './dummydata.js';
import { dummyData, dummyDataMonth, dummyDataDay, dummyDataNone } from './dummydata.js';

@Component({
    selector: 'app-chart-parent',
    templateUrl: './chart-parent.component.html',
    styleUrls: ['./chart-parent.component.scss'],
    standalone: false
})
export class ChartParentComponent implements OnInit, OnChanges {
  @Input() appName: string;
  @Input() apiName: string;
  @Input() metricType: string;
  @Input() chartHeightPx = '400';
  @Input() useSampleData = false;
  chartData: any[] = [];
  loadingChartData: boolean;
  chartExtraAttributes: {
    chartHeight: string;
    dateTo: Date;
    dateFrom: Date;
    noData: boolean;
    sortingTitleName: string;
  };
  previousDateRange: { title: string; contents: Element };
  dateRange = ['Last Day', 'Last Week', 'Last Month'];
  endpointColors = ['253,164,129', '59,207,240', '77,182,158', '161,140,222'];
  selectedColorsArr = [];
  sortingTitle = {
    trafficSum: 'Total Traffic',
    errorSum: 'Total Errors',
    avgTransactionPerSecond: 'Avg Transactions (ms)',
    avgTotalResponseTime: 'Avg Time (ms)',
    avgRequestProcessingLatency: 'Avg Latency (ms)',
  };
  apigeeRequestSwitch = new Subject();
  timeUnit: string;

  constructor(private analyticsService: AnalyticsService, private changeRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.chartExtraAttributes = {
      chartHeight: this.chartHeightPx,
      dateFrom: new Date(),
      dateTo: new Date(),
      noData: false,
      sortingTitleName: this.sortingTitle[this.metricType],
    };

    this.apigeeRequestSwitch
      .pipe(switchMap((requestBody: AnalyticsRequestBody) => this.analyticsService.getAnalytics(requestBody)))
      .subscribe((resData: any) => {
        if (resData.analytics) {
          this.prepareChartData(resData.analytics);
          this.loadingChartData = false;
        }
      });
  }

  ngAfterContentChecked() {
    this.changeRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.apiName) {
      if (!changes.apiName.firstChange) {
        this.setDateRange({ title: 'apiChange', contents: document.createElement('div') });
      }
    }

    if (changes.appName) {
      if (!changes.appName.firstChange) {
        this.setDateRange({ title: 'apiChange', contents: document.createElement('div') });
      }
    }
  }

  makeAnalyticsCall(dateFrom: Date, dateTo: Date) {
    const requestBody: AnalyticsRequestBody = {
      appName: this.appName,
      apiName: this.apiName,
      metricType: this.metricType,
      dateTo: dateTo,
      dateFrom: dateFrom,
      timeUnit: this.timeUnit,
    };

    this.loadingChartData = true;
    this.chartData = [];
    // console.log(requestBody);

    // Create call below...
    if (this.useSampleData) {
      setTimeout(() => {
        this.prepareChartData(dummyDataDay.analytics);
        this.loadingChartData = false;
      }, 200);
    } else {
      this.apigeeRequestSwitch.next(requestBody);
    }
  }

  setDateRange(tabDateRange: { title: string; contents: Element }) {
    const dateTo: Date = new Date();
    let dateFrom: Date;
    let selectedDateRange: any;

    if (tabDateRange !== this.previousDateRange && tabDateRange.title !== 'apiChange') {
      selectedDateRange = this.dateRange.find((x) => tabDateRange.title === x);
      dateFrom = this.analyticsService.getDateFrom(dateTo, selectedDateRange);
      this.timeUnit = selectedDateRange === 'Last Day' ? 'hour' : 'day';
      this.previousDateRange = tabDateRange;
    } else {
      selectedDateRange = this.dateRange.find((x) => this.previousDateRange.title === x);
      dateFrom = this.analyticsService.getDateFrom(dateTo, selectedDateRange);
    }
    this.chartExtraAttributes.dateTo = dateTo;
    this.chartExtraAttributes.dateFrom = dateFrom;
    this.makeAnalyticsCall(dateFrom, dateTo);
  }

  prepareChartData(dataAnalytics: AnalyticsDataObj[]) {
    let emptyDataCounter = 0;
    for (let endpointSection of dataAnalytics) {
      endpointSection = this.dataStyle(endpointSection);
      if (endpointSection.data.length === 0) {
        emptyDataCounter++;
      } else {
        // Compute offset for day so timezone hours are not skewed
        if (this.timeUnit === 'day') {
          endpointSection.data.map((dataPoint: any) => {
            dataPoint.x = new Date(new Date(dataPoint.x).getTime() + new Date().getTimezoneOffset() * 60000);
            return dataPoint;
          });
        }
      }
    }

    // No data in any endpoint
    if (emptyDataCounter === dataAnalytics.length) {
      this.chartExtraAttributes.noData = true;
    } else {
      this.chartExtraAttributes.noData = false;
    }

    // Deep copy
    this.chartData = dataAnalytics.map((a: AnalyticsDataObj) => Object.assign({}, a));
  }

  dataStyle(endpointSection: AnalyticsDataObj) {
    const colorAttributes = this.colorPicker();
    endpointSection.backgroundColor = `rgba(${colorAttributes.selectedColor},${colorAttributes.selectedOpacity})`;
    endpointSection.borderColor = `rgba(${colorAttributes.selectedColor},1)`;
    endpointSection.pointHoverBackgroundColor = `rgba(${colorAttributes.selectedColor},0.83)`;
    endpointSection.pointHoverBorderColor = `rgba(${colorAttributes.selectedColor},1)`;
    endpointSection.pointBackgroundColor = `rgba(${colorAttributes.selectedColor},0.83)`;
    endpointSection.pointBorderColor = `rgba(${colorAttributes.selectedColor},1)`;
    endpointSection.pointHoverRadius = '6';
    endpointSection.radius = '3';
    endpointSection.fill = colorAttributes.fill;

    endpointSection.pointBackgroundColor = `rgba(${colorAttributes.selectedColor},0.83)`;
    endpointSection.pointBorderColor = `rgba(${colorAttributes.selectedColor},1)`;

    endpointSection.unitType = this.metricType !== 'trafficSum' && this.metricType !== 'errorSum' ? 'ms' : '';
    return endpointSection;
  }

  colorPicker() {
    const opacity = ['0.1', '0.2', '0.3'];
    const selectedColor = this.endpointColors[Math.floor(Math.random() * this.endpointColors.length)];
    const selectedOpacity = opacity[Math.floor(Math.random() * opacity.length)];

    this.endpointColors.splice(this.endpointColors.indexOf(selectedColor), 1);
    this.selectedColorsArr.push(selectedColor);

    if (this.endpointColors.length === 0) {
      this.endpointColors = Object.assign([], this.selectedColorsArr);
      this.selectedColorsArr = [];
    }

    return {
      selectedColor: selectedColor,
      selectedOpacity: selectedOpacity,
      fill: true,
    };
  }
}
