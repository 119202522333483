import { LoaderService } from 'app/services/loader.service';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'app/services/dashboard.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'dashboard-monitoring',
    templateUrl: './monitoring.component.html',
    styleUrls: ['./monitoring.component.scss'],
    standalone: false
})
export class DashboardMonitoringComponent implements OnInit {
  showAPIKeyModal = false;
  apiKeys = [];
  mappings = [];
  apisFromLicenses = [];
  subscription = new Subscription();
  selectedMetric: string;
  selectedAPI: any;
  dropdownOptions: string[] = [];

  constructor(private dashboardService: DashboardService, private loaderService: LoaderService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.loaderService.loadingContent.emit(true);

    this.dashboardService.getAndSetTokens();

    this.subscription.add(
      this.dashboardService.mappings.subscribe((mapping) => {
        if (mapping && Object.keys(mapping).length !== 0) {
          this.mappings = mapping;
        }
      })
    );

    this.subscription.add(
      this.dashboardService.apisFromLicenses.subscribe((licenseAPIs) => {
        if (licenseAPIs && Object.keys(licenseAPIs).length !== 0) {
          this.apisFromLicenses = licenseAPIs;
          this.createDropdownOptions(this.apisFromLicenses);
        }
      })
    );

    this.subscription.add(
      this.dashboardService.apiKeys.subscribe((apiKeys) => {
        if (apiKeys !== 'empty') {
          if (apiKeys && Object.keys(apiKeys).length !== 0) {
            this.apiKeys = apiKeys;
          }
          this.loaderService.loadingContent.emit(false);
        }
      })
    );
  }

  switchAPIKeyModal() {
    this.showAPIKeyModal = !this.showAPIKeyModal;
  }

  createDropdownOptions(apisFromLicenses: any) {
    this.dropdownOptions = [];
    for (const appAPI of apisFromLicenses) {
      if (appAPI.apiTitles) {
        for (const apiTitleName of appAPI.apiTitles) {
          this.dropdownOptions.push(apiTitleName);
        }
      } else {
        for (const apiName of appAPI.apis) {
          this.dropdownOptions.push(apiName);
        }
      }
    }

    this.setSelectedAPI(
      this.apisFromLicenses[0],
      this.apisFromLicenses[0].apiTitles ? this.apisFromLicenses[0].apiTitles[0] : this.apisFromLicenses[0].apis[0],
      this.apisFromLicenses[0].apiSpec[0]
    );
  }

  setSelectedMetric(metric: { title: string }) {
    this.selectedMetric = metric.title;
  }

  selectedAPIDropdown(api: string) {
    for (const app of this.apisFromLicenses) {
      if (app.apiTitles) {
        if (app.apiTitles.includes(api)) {
          this.setSelectedAPI(app, app.apis[app.apiTitles.indexOf(api)], app.apiSpec[app.apiTitles.indexOf(api)]);
        }
      } else {
        if (app.apis.includes(api)) {
          this.setSelectedAPI(app, api, app.apiSpec[app.apis.indexOf(api)]);
        }
      }
    }
  }

  setSelectedAPI(app: any, apiName: string, apiSpec: string) {
    this.selectedAPI = {
      ...app,
      apiName,
      apiSpec,
    };
  }
}
