import { AnalyticsDataObj } from 'app/models/analyticsDataObj.interface';
import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { LegendItem } from 'chart.js';
import * as Chart from 'chart.js';
import 'chartjs-adapter-date-fns';
import moment from 'moment';

@Component({
    selector: 'app-line-chart',
    templateUrl: './line-chart.component.html',
    styleUrls: ['./line-chart.component.scss'],
    standalone: false
})
export class LineChartComponent implements OnInit, OnChanges {
  @ViewChild(BaseChartDirective, { static: true }) chartComponent: any;
  @Input() chartData: AnalyticsDataObj[];
  @Input() dateStepSize: Chart.TimeUnit = 'hour';
  @Input() chartExtraAttributes: any = {};
  legendData: any;
  legendSelected: {
    index: number;
    last: HTMLElement;
  };
  sortDirection = 'asc';
  noData = false;
  // Graph Colors
  gridLineSilver = 'rgb(245,247,248,0.2)';
  labelTickSilver = 'rgba(159,162,180,1)';

  constructor() {}
  ngOnInit() {
    this.legendSelected = {
      index: -1,
      last: null,
    };
  }

  ngOnChanges() {
    if (this.chartComponent.chart) {
      if (this.dateStepSize === 'day') {
        this.chartComponent.options.scales.xAxes.time.unit = 'hour';
      } else {
        this.chartComponent.options.scales.xAxes.time.unit = 'day';
      }

      if (this.chartExtraAttributes) {
        this.setEmptyChart(this.chartExtraAttributes.noData);
      }

      this.chartComponent.update();
    }
  }

  setEmptyChart(noData: boolean) {
    if (noData) {
      this.chartComponent.chart.options.scales.xAxes.ticks.min = this.chartExtraAttributes.dateFrom;
      this.chartComponent.chart.options.scales.xAxes.ticks.max = this.chartExtraAttributes.dateTo;
      this.chartComponent.chart.options.scales.y.ticks.max = 5;
      this.chartComponent.chart.options.scales.y.ticks.maxTicksLimit = 11.1;
      this.noData = true;
    } else {
      this.chartComponent.chart.options.scales.xAxes.ticks.min = undefined;
      this.chartComponent.chart.options.scales.xAxes.ticks.max = undefined;
      this.chartComponent.chart.options.scales.y.ticks.max = undefined;
      this.chartComponent.chart.options.scales.y.ticks.maxTicksLimit = undefined;
      this.noData = false;
    }
    this.chartComponent.update();
  }

  /**
   * Also sets boundaries if there is no data to display for an endpoint
   * @param event Mouse event
   * @param legendLabelIndex Index of legend label from legendData
   */
  legendOnClick(event: MouseEvent, legendLabelIndex: LegendItem, leIndex: number) {
    // const index = legendLabelIndex.datasetIndex;
    const index = leIndex;
    const eventTarget = event.target as HTMLElement;
    const chart = this.chartComponent.chart;
    let chartDataset = chart.getDatasetMeta(index);

    this.setEmptyChart(false);
    chartDataset.hidden = false;

    // Was clicked last
    if (this.legendSelected.last) {
      this.legendSelected.last.classList.remove('-active');
    }

    // Index already selected. Show all
    if (this.legendSelected.index === index) {
      for (const chartIndex in this.chartData) {
        chartDataset = chart.getDatasetMeta(chartIndex);
        chartDataset.hidden = false;
      }
      this.legendSelected.index = -1;
      chart.update();
      return;
    }

    // Show only one dataset
    for (const chartIndex in this.chartData) {
      if (parseInt(chartIndex, 10) !== index) {
        chartDataset = chart.getDatasetMeta(chartIndex);
        chartDataset.hidden = true;
      } else {
        this.legendSelected.index = index;
        if (chart.getDatasetMeta(chartIndex).data.length === 0) {
          this.setEmptyChart(true);
        }
      }
    }

    this.legendSelected.last = eventTarget;
    eventTarget.classList.add('-active');
    chart.update();

    // Below requires a backup of chartdata
    // Part 1: Restore original data for selected endpoint
    // this.chartData[index].data = Object.assign([], this.chartDataBackup[index].data);

    // Part 2: Restore all datasets
    // this.chartDataBackup.map((_x: {}, backupIndex) => {
    //   this.chartData[backupIndex].data = this.chartDataBackup[backupIndex].data;
    // });

    // Part 3: Show only selected dataset
    // Keep datapoints and have animations of datasets sliding
    // this.chartData[chartDataIndex].data = this.chartData[chartDataIndex].data.map((dataPoint: { x: Date }) => {
    //   return {
    //     y: 0,
    //     x: dataPoint.x
    //   }
    // });
  }

  legendSort() {
    let sortedLabels: string[] = [];
    let sortedChartData: any[];
    this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';

    if (this.sortDirection === 'desc') {
      sortedChartData = this.chartData.sort((a: { totalOrAvg: number }, b: { totalOrAvg: number }) => {
        return b.totalOrAvg - a.totalOrAvg;
      });
    } else {
      sortedChartData = this.chartData.sort((a: { totalOrAvg: number }, b: { totalOrAvg: number }) => {
        return a.totalOrAvg - b.totalOrAvg;
      });
    }

    sortedLabels = sortedChartData.map((x: { label: string }) => x.label);
    this.chartComponent.update();
  }

  public lineChartOptions: Chart.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: function (context) {
          const tooltipModel = context.tooltip;
          // Tooltip Element
          let tooltipEl = document.getElementById('chart-tooltip');
          let titleLines: string[];
          let bodyLines: string[];
          let innerHtml: string;
          let colors: { backgroundColor: string; borderColor: string };
          let style: string;
          let span: string;
          let tableRoot: HTMLElement;
          let position: any = null;

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chart-tooltip';
            tooltipEl.className = 'chart-tooltip -container';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('center', 'left', 'right', 'no-transform');
          if (tooltipModel.xAlign) {
            tooltipEl.classList.add(tooltipModel.xAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          // context.chart.data.datasets

          // Set Text
          if (tooltipModel.body) {
            titleLines = tooltipModel.title || [];
            bodyLines = tooltipModel.body.map((bodyItem: any) => {
              return bodyItem.lines;
            });

            innerHtml = '<thead>';
            titleLines.forEach(function (title) {
              innerHtml += `<tr><th class="chart-tooltip -title">${title}</th></tr>`;
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function (body, i) {
              colors = tooltipModel.labelColors[i] as unknown as { backgroundColor: string; borderColor: string };
              style = 'background:' + colors.backgroundColor;
              style += '; border-color:' + colors.borderColor;
              style += '; border-width: 8px ; border-radius: 50px';
              style += '; width: 8px ; height: 8px ; display: inline-block; margin-right: 10px';
              span = '<div style="' + style + '"></div>';
              innerHtml += '<tr><td class="chart-tooltip -body">' + span + body + '</td></tr>';
            });
            innerHtml += '</tbody>';

            tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
      },
    },
    animation: {
      duration: 500,
      easing: 'easeInQuad',
    },
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 2,
      },
    },
    scales: {
      xAxes: {
        position: 'bottom',
        type: 'time',
        grid: { color: this.gridLineSilver },
        time: {
          unit: this.dateStepSize,
          tooltipFormat: 'MMM dd, yyyy',
          displayFormats: {
            hour: 'HH:mm',
            day: 'MMM D',
          },
        },
        ticks: {
          color: this.labelTickSilver,
          font: { size: 13 },
          padding: 6,
          maxRotation: 0,
          autoSkip: false,
          minRotation: 0,
          callback: (tick: string, index: number, array: []) => {
            // This callback only deals with displaying labels
            let isDivisible: number;
            if (index === 0 || index === array.length - 1) {
              return null;
            } else {
              // console.log(this.dateStepSize);
              switch (this.dateStepSize) {
                case 'day':
                  // Show every other label
                  isDivisible = index % 2;
                  break;
                case 'week':
                  // Show every label
                  isDivisible = index % 1;
                  break;
                case 'month':
                  // Show every 3 labels
                  isDivisible = index % 3;
                  break;
                default:
                  isDivisible = index % 2;
              }

              const formattedTick =
                this.dateStepSize === 'day' ? moment(tick).format('HH:mm') : moment(tick).format('MMM D');

              return isDivisible ? null : formattedTick;
            }
          },
        },
        stacked: true,
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        grid: { color: this.gridLineSilver },
        ticks: {
          precision: 0,
          color: this.labelTickSilver,
          font: { size: 13 },
          padding: 6,
          maxTicksLimit: 10,
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
  };

  public lineChartType = 'line';
  public lineChartPlugins = [];
}
