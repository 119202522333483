import { DashboardMonitoringComponent } from '../../pages/_dashboard/monitoring/monitoring.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from 'app/services/analytics.service';
import { ThemeService, BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { AnalyticsRoutingModule } from 'app/modules/analytics/analytics-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ChartParentComponent } from 'app/components/_analytics/chart-parent/chart-parent.component';
import { LineChartComponent } from 'app/components/_analytics/line-chart/line-chart.component';

@NgModule({
  declarations: [LineChartComponent, ChartParentComponent, DashboardMonitoringComponent],
  imports: [CommonModule, SharedModule, AnalyticsRoutingModule, BaseChartDirective],
  providers: [AnalyticsService, ThemeService, provideCharts(withDefaultRegisterables())],
  exports: [ChartParentComponent],
})
export class AnalyticsModule {}
